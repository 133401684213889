var ua = navigator.userAgent.toLowerCase();
var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
var isIphone = (ua.indexOf("iphone") > -1 || ua.indexOf("ipod") > -1); 

$.easing.easeout = function (x, t, b, c, d) {
	return (t==d) ? b+c : c * (-Math.pow(2, -10 * t/d) + 1) + b;
};

$.easing.easeInOutQuad = function (x, t, b, c, d) {
	if ((t/=d/2) < 1) return c/2*t*t + b;
	return -c/2 * ((--t)*(t-2) - 1) + b;
};

$(function(){
    $.extend($.fn.disableTextSelect = function() {
        return this.each(function(){
           $(this).css('MozUserSelect','none');
           $(this).bind('selectstart',function(){return false;});
           $(this).mousedown(function(){return false;});
        });
    });
    $('.noSelect').disableTextSelect();//No text selection on elements with a class of 'noSelect'
});

$(window).load(function(){
	
	prepareMainParalax();
	
	if(!(isAndroid || isIphone)){
		$(".paralax").parallax();
	}
		
	$('.bxslider').bxSlider({
		 startSlide:0,
		 useCSS: false
	});

	$(window).scroll(function() {
	    var scroll = $(window).scrollTop();

	     //>=, not <=
	    if (scroll >= 100) {
	        //clearHeader, not clearheader - caps H
	        $(".sticky-wrapper").addClass("smaller");
	    };
			if (scroll <= 100) {
	        //clearHeader, not clearheader - caps H
	        $(".sticky-wrapper").removeClass("smaller");
	    };
	});

	$("img.lazy").lazyload({
		threshold : 200,
		skip_invisible : false
	});

	$("#age .age-yes").click(function(){
		$("body").addClass($("body").attr("layout"));
		$.cookie('age', '1', { expires: 7, path:'/' });
		$("#age,#age-overlay").fadeOut();
		return false;
	});

	$("#age .age-no").click(function(){
		alert("Omlouváme se, ale stránky jsou určeny pouze osobám starším osmnácti let.")
		return false;
	});

	$('#nav').waypoint('sticky');

	$("#nav .local").click(function(){
		if ($("#nav").hasClass('open')) {
			$("#nav").removeClass('open');
			$("#nav ul").slideToggle();
		}

		var id = $(this).attr("href");
		var offsetTop = $("#nav").height();
		$.scrollTo(id,1500, {
			offset: {top:-offsetTop},
			easing:'easeInOutQuad'
		});
		return false;
	});

	$("#nav ul li a").click(function(){
		if ($("#nav").hasClass('open')) {
			$("#nav").removeClass('open');
			$("#nav ul").slideToggle();
		}

		var id = $(this).attr("href");
		var offsetTop = $("#nav").height();
		$.scrollTo(id,1500, {
			offset: {top:-offsetTop},
			easing:'easeInOutQuad'
		});
		return false;
	});
	
	var hash = window.location.hash;
	if(window.location.href.indexOf("produkty") !== -1){
		var pos = window.location.href.indexOf("produkty");
		hash = window.location.href.substring(pos + 9);
		if(hash.indexOf("#") !== -1){
			hash = hash.substring(0, hash.indexOf("#"));
		}
		if(hash.indexOf("/") !== -1){
			hash = hash.substring(0, hash.indexOf("/"));
		}
		
		hash = "#" + hash; 
	}
	
	console.log(hash);
	
	if(hash && $(hash).length) {
		setTimeout(function(){ 
			var offsetTop = $("#nav").height();
			offsetTop += 40;
			console.log(offsetTop);
			var element = $(hash).parents(".bottle-item").first();
			$.scrollTo(hash,1500, {
				offset: {top:-offsetTop},
				easing:'easeInOutQuad',
				onAfter: function(){
					if(element){
						chbzTabs.bootleItemClick(element); 
					}
				}
			});
		}, 1000);
	}
	

	$("#nav a[href='#aktuality']").magnificPopup({
        type:'inline'
    });

	$('#nav h2').on('tap', function(e) {
		$("#nav").toggleClass('open');
		$("#nav ul").slideToggle();
	});

	$(".bottle-item .soldOut").each(function () {
		var soldBanner = $(this);
		$(this).parents(".bottle-item").find(".bottle-img").hover(function () {
			soldBanner.stop( true, true ).slideToggle("fast");
		}, function () {
			soldBanner.stop( true, true ).slideToggle("fast");
		})
	});

	$("#nabidka-prace .news-title").click(function () {
		$(this).toggleClass("active");
		$(this).next(".news-desc").toggleClass("active");
	});

	$("#nabidka-prace .closeit a").click(function () {
		console.log($(this).parents(".news-item"), $(this).parents(".news-item").find(".active"));
		$(this).parents(".news-item").find(".active").removeClass("active");
	});

});
	
$(function() { 
  $('a[href*=#]:not([href=#])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top
        }, 1000);
        return false;
      }
    }
  });
});

/* spuštění animace produktu, když se objeví na obrazovce */

function isElementInViewport(elem) {
	var $elem = $(elem);
	
	// Get the scroll position of the page.
	var scrollElem = ((navigator.userAgent.toLowerCase().indexOf('webkit') != -1) ? 'body' : 'html');
	var viewportTop = $(scrollElem).scrollTop();
	var viewportBottom = viewportTop + $(window).height();
	
	// Get the position of the element on the page.
	var elemTop = Math.round( $elem.offset().top );
	var elemBottom = elemTop + $elem.height();
	
	return ((elemTop < viewportBottom) && (elemBottom > viewportTop));
}

// Check if it's time to start the animation.
function checkAnimation() {
	var $elem = $('.animated');
	
	$elem.each(function(){
		var $singleElement = $(this);
		// If the animation has already been started
		if ($singleElement.hasClass('fadeIn')) return;
		
		if (isElementInViewport($singleElement)) {
			// Start the animation
			$singleElement.addClass('fadeIn');
		}
	});
}

// Capture scroll events
$(window).scroll(function(){
	checkAnimation();
});

function prepareMainParalax(){
	return;
	var paralaxAttr = {
		"data-bottom-top": "transform: translateY(0px);",
		"data-top-top": "transform: translateY(-1000px);"
	};
	var div1 = $("<div>").append($("#predel_5")).append($("#page2")).append($("#footer"));
	var div2 = $("<div>").append($("#vina5")).append(div1);
	var div3 = $("<div>").append($("#predel_4")).append($("#vina6")).append(div2);
	var div4 = $("<div>").append($("#paralax-lipka")).append($("#vina4")).append(div3);
	var div5 = $("<div>").append($("#predel_2")).append($("#vina3")).append(div4);
	var div6 = $("<div>").append($("#predel_1")).append($("#vina2")).append(div5).appendTo("body");
	
	div1.css("background-color", "#fff");
	div2.css("background-color", "#fff");
	div3.css("background-color", "#fff");
	//div4.css("background-color", "#fff");
	div5.css("background-color", "#fff");
	div6.css("background-color", "#fff");
	
	div1.attr({
		"data-5000-bottom-top": "transform: translateY(0px);",
		"data-5000-top-top": "transform: translateY(-1000px);"
	});
	div2.attr({ 
		"data-4000-bottom-top": "transform: translateY(0px);",
		"data-4000-top-top": "transform: translateY(-1000px);"
	});
	div3.attr({
		"data-3000-bottom-top": "transform: translateY(0px);",
		"data-3000-top-top": "transform: translateY(-1000px);"
	});
	div4.attr({
		"data-2000-bottom-top": "transform: translateY(0px);",
		"data-2000-top-top": "transform: translateY(-1000px);"
	});
	div5.attr({
		"data-1000-bottom-top": "transform: translateY(0px);",
		"data-1000-top-top": "transform: translateY(-1000px);"
	});
	div6.attr({
		"data-bottom-top": "transform: translateY(0px);",
		"data-top-top": "transform: translateY(-1000px);"
	});
	
	var s = skrollr.init(); 
	
	
	
	//.append($(""))
}

function createCookie(name, value, mins) {
	if (mins) {
		var date = new Date();
		date.setTime(date.getTime() + (mins * 30 * 1000));
		var expires = "; expires=" + date.toGMTString();
	} else
		var expires = "";
	document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for ( var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ')
			c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) == 0)
			return c.substring(nameEQ.length, c.length);
	}
	return null;
}

function eraseCookie(name) {
	createCookie(name, "", -1);
}

$(document).ready(function() {

	// var showbanner = readCookie('banner-soutez-2019');
	var showbanner = false; // banner/upoutávku na webu zobrazovat při každém příchodu na stránku/refreshi stránek, ať to vždy všichni vidí...

	if (!showbanner) {
		$("#banner-soutez-2019").css("display", "flex");
		$("#banner-soutez-2019 #close-banner, #banner-soutez-2019").click(function () {
			$("#banner-soutez-2019").fadeOut("fast", function () {
				createCookie('banner-soutez-2019', true, 24 * 60);
			});
		});
	}
});
