chbzTabs = {
	
	init: function(rowSizeDefault)
	{
		$(".vina").each(function(){ // pro vsechny vypisy kategorii vin
			
			// moznost nastaveni poctu vin na radek pres parametr
			rowAttr = $(this).attr("rowCount"); 

			if(typeof rowAttr !== typeof undefined && rowAttr !== false){
				rowSize = rowAttr;
			}
			else{
				rowSize = rowSizeDefault;
			}

			var popisy = [];
			var i = 0;
			var len = $(this).find(".bottle-list .bottle-item").length;
			$(this).find(".bottle-list .bottle-item").each(function(index, element){
				// vytahnu elementy popisu do pole
				popisy.push($(this).find(".bottle-desc"));

				$(this).attr("position", (i % rowSize)); // kazdemu nastavim pozici podle iteratoru a poctu lahvi na radku (0,1,2...)

				// pokud pocet vypsanych dosahne nastaveneho poctu na radku
				// vypisu clearfix za posledni flasku, potom za ni vypisu ulozene popisy a nakonec cruiser
				// sklada se to za flasku, takze vznikne poradi flaska - cruiser - popisy - clearfix
				// obnovi se pole s popisy a pokracuje se dal na dalsi flasky...
				if((i % rowSize == rowSize - 1) || (index == len - 1)){
					$(this).after('<div class="clearfix"></div>');
					for(p in popisy){
						$(this).after(popisy[p]);
					}
					var cnt = popisy.length;
					$(this).after('<div class="cruiser"><div class="cruiser-pointer size'+cnt+'"></div></div>');
					popisy = [];
				}

				i++;
			});
		});
		
		$('.bottle-list .closeit a').click(function(event){
			event.preventDefault();
			var id = $(this).attr("close");
			chbzTabs.closeTab(id);
		});
		
		$('.bottle-list .bottle-item').click(function(){
			chbzTabs.bootleItemClick(this); 
		});
	},
	
	destroy: function()
	{
		$(".vina").each(function(){
			$(this).find(".products .bottle-list .cruiser").remove(); // vyhazim cruisery
			$(this).find(".products .bottle-list > .clearfix").remove(); // vyhazim clearfix, ale jen v bottle-list ne hloubs...
			$(this).find(".products .bottle-list .bottle-desc").each(function(){ // vratim popisy na jejich puvodni misto
				var id = $(this).attr("id");
				$(".bottle-item[data-tab='"+id+"']").append($(this));
			});
		});
		
		$('.bottle-list .closeit a').unbind( "click" );
		$('.bottle-list .bottle-item').unbind( "click" );
	},
	
	bootleItemClick: function(element)
	{
		$('.bottle-list .bottle-desc').stop(true, true); // zastavim animace aby se nestosovaly za sebe
		
		var tab_id = $(element).attr('data-tab'); // id popisu
		
		// hybani s cruiserem
		cruiser = $(element).nextAll('.cruiser').first().find(".cruiser-pointer"); // najdu nejblizsi dalsi cruiser (ohranicuje konec cesti s lahvemi)
		clearfix = $(element).nextAll('.clearfix').first(); // najde nejblizsi dalsi div.clearfix (ohranicuje radku produktu)
		clearfix.prevUntil('.clearfix', ":not(.bottle-desc)").removeClass('active'); // od clearfix postupuje nahoru a vsem prvkum odstrani class 'active', zastavi se u dalsiho clearfix (hranice predchoziho radku) nebo u zacatku elementu
		$(element).addClass('active'); // nastavi active u lahve
		// ## hybani s cruiserem ##
		
		// aktivni desc v aktualnim bloku (od cruiseru do clearfix)
		var activeCnt = clearfix.prevUntil('.clearfix', ".bottle-desc.active").length;
		var activeElId = clearfix.prevUntil('.clearfix', ".bottle-desc.active").attr("id");
		
		var close = false;
		
		if(activeCnt && (tab_id == activeElId)){ // pokud je nejaky popis otevreny a klepnul jsem na stejnou lahev zavru popis nahoru
			
			$("#"+tab_id).slideUp(1000, function(){
				$(this).removeClass("active").trigger('activeChange');
				clearfix.prevUntil('.clearfix', ".bottle-desc").hide().removeClass("active");
				cruiser.removeClass("active");
				if (!$('#vina5 .cruiser-pointer').hasClass('active')) { // u #vina5 (lipka) je slider se sipkama. pokud je cruiser neaktivni, sipky schovam 
					$('.control-slide').removeClass('active');
				};
				$(element).removeClass("active"); 
			});
			close = true;
		}
		else if(activeCnt){ // pokud je nejaky popis aktivni a kliknul jsem na jinou lahev
			$("#"+activeElId).fadeOut('slow', function(){ // zavru aktualni popis
				$("#"+tab_id).fadeIn('slow').addClass("active").trigger('activeChange'); // otevru jiny popis
				$(this).removeClass("active");
			});
		}
		else{ // pokud neni zadny aktivni, popis sjede dolu
			$("#"+tab_id).slideDown(1000, function() {
				$(this).addClass("active").trigger('activeChange');
				
				// pro uzsi display se scrolluje na popis - presunuto aby se spustilo az po otevreni popisu
				if (window.innerWidth < 800) {
					var $anchor = $('#'+tab_id).offset();
					var navHeight = $("#nav #logo").outerHeight();
					var scroll = $anchor.top - navHeight; 
					$('body').animate({ scrollTop: scroll });
				}
			});
		}
		
		if(!close){
			cruiser.addClass('active');
			
			position = $(element).attr('position');
			
			if (position == 0) {
				cruiser.removeClass('gotoright').removeClass('gotorighttotally').removeClass('gotomiddle');
			};
			
			if (position == 1) {
				cruiser.removeClass('gotoright').removeClass('gotorighttotally').addClass('gotomiddle');
			};
			
			if (position == 2) {
				cruiser.removeClass('gotomiddle').removeClass('gotorighttotally').addClass('gotoright');
			};
			if (position == 3) {
				cruiser.removeClass('gotomiddle').removeClass('gotoright').addClass('gotorighttotally');
			};
			
			if ($('#vina5 .cruiser-pointer').hasClass('active')) {
				$('.control-slide').addClass('active');
			};
		}
	},

	closeTab: function(tab_id){
		
		clearfix = $(".bottle-list #" + tab_id).nextAll('.clearfix').first(); // najde nejblizsi dalsi div.clearfix (ohranicuje radku produktu)
		cruiser = clearfix.prevAll('.cruiser').first().find(".cruiser-pointer");
		
		$("#"+tab_id).slideUp(1000, function(){
			$(this).removeClass("active").trigger('activeChange');
			clearfix.prevUntil('.clearfix', ".bottle-desc").hide().removeClass("active");
			cruiser.removeClass("active");
			clearfix.prevUntil('.clearfix').removeClass('active');
		});
	}
};

// ParamAjax.P_initialize("param_filter","{$response->getVar("ajaxUri")}&param_reload=1", "param_filter");



$(document).ready(function(){
	
	var rowSizeDefault = 3;
	if (window.innerWidth < 800) {
		rowSizeDefault = 2;
	}
	if (window.innerWidth < 480) {
		rowSizeDefault = 1;
	}
	
	chbzTabs.init(rowSizeDefault);
	
	$(window).on('resize',function(){
		
		var rowSize = 3;
		
		if (window.innerWidth < 800) {
			rowSize = 2;
		}
		if (window.innerWidth < 480) {
			rowSize = 1;
		}
		
		if(rowSize != rowSizeDefault){
			rowSizeDefault = rowSize;
			chbzTabs.destroy();
			chbzTabs.init(rowSizeDefault);
		}
	});
	
	$( ".control-slide.left" ).click(function() {
		$(this).parents(".bottle-list").find(".bottle-item.active").prev(".bottle-item").click();
	});
	
	$( ".control-slide.right" ).click(function() {
		$(this).parents(".bottle-list").find(".bottle-item.active").next(".bottle-item").click();
	});
	
	
	var sliderLipka = $(".bxslider-lipka").bxSlider({
		 startSlide:0,
		 useCSS: false
	});
	
	
	
    $('ul.tabs li').click(function(){
        var tab_id = $(this).attr('data-tab');
        
        var activeCnt = $(".bzenecka-lipka .tab-content.active").length;
    	var activeElId = $(".bzenecka-lipka .tab-content.active").attr("id");
    	
    	var close = false

    	if(activeCnt && (tab_id == activeElId)){
    		$("#"+tab_id).slideUp(1000, function(){
    			$(this).removeClass("active");
    			$('ul.tabs li').removeClass('active');
    			$('.bzenecka-lipka .cruiser-pointer').removeClass('active');
    		});
    		close = true;
    	}
    	else if(activeCnt){
    		$("#"+activeElId).fadeOut('slow', function(){
    			$("#"+tab_id).fadeIn('slow').addClass("active");
    			$(this).removeClass("active");
    			if(tab_id == "lipka-fotogalerie"){
    				sliderLipka.reloadSlider();
    			}
    		});
    	}
    	else {
    		$('.bzenecka-lipka .cruiser-pointer').addClass('active');
    		$("#"+tab_id).slideDown(1000, function() {
    			$(this).addClass("active");
    			if(tab_id == "lipka-fotogalerie"){
    				sliderLipka.reloadSlider();
    			}
    		});
    	}

    	if(!close){
    		
    		$('.bzenecka-lipka .cruiser-pointer').addClass('active');
    		position = $(this).attr('position');
    		
    		if (position == 1) {
    			$('.bzenecka-lipka .cruiser-pointer').removeClass('gotoright').removeClass('gotomiddle');
    		};
    		
    		if (position == 2) {
    			$('.bzenecka-lipka .cruiser-pointer').removeClass('gotoright').addClass('gotomiddle');
    		};
    		
    		if (position == 3) {
    			$('.bzenecka-lipka .cruiser-pointer').removeClass('gotomiddle').addClass('gotoright');
    		};
    	}

    });
    
    $('.bzenecka-lipka .closeit a').click(function(event){
		event.preventDefault();
		var element = $(this).parents(".tab-content");
		element.slideUp(1000, function(){
			$(this).removeClass("active");
			$('ul.tabs li').removeClass('active');
			$('.bzenecka-lipka .cruiser-pointer').removeClass('active');
		});
	});

});

