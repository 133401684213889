
$(document).ready(function()
	{
	var imageVinice = $('#image-vinice');
	var src = imageVinice.attr("src");

	var pic_real_width, pic_real_heigh, ratio;
	
	$("<img/>") // Make in memory copy of image to avoid css issues
		.attr("src", imageVinice.attr("src"))
		.load(function() {
			pic_real_width = this.width;   // Note: $(this).width() will not
			pic_real_height = this.height; // work for in memory images.
			ratio = pic_real_width / pic_real_height;
			var img_w = $("#vina5").width();
			var img_h = img_w / ratio;
			
			imageVinice.mapster('resize', img_w, img_h, 0);
	});
	
	imageVinice.mapster({
		mapKey: 'data-key',		fill: false,
	    stroke: false, 
	    strokeColor: 'C61D45',
	    singleSelect: true,
	    strokeWidth: 0,
	    
        render_select: {
        	fill: false,
            stroke: false, 
    	    strokeColor: 'C61D45',
    	    strokeWidth: 0,
        },	    onClick: function(data){
	    	var tid = $(this).attr("href");
	    	chbzTabs.bootleItemClick(tid);
	    },
        onStateChange: function(data){
        	var key = data.key;
        	var state = data.state;
        	var selected = data.selected;
        	if(state == "highlight"){
        		$("#vinice-map-wrap .vinice-overlay."+key).toggleClass("hover", selected);
        	}
        	else if(state == "select"){
        		$("#vinice-map-wrap .vinice-overlay."+key).toggleClass("active", selected);
        	}
        }
	});
	
 	$( window ).resize(function() {
		var img_w = $("#vina5").width();
		var img_h = img_w / ratio;
	
	 	imageVinice.mapster('resize', img_w, img_h, 0);
 	}); 
 	
 	$('#vina5 .bottle-list .bottle-desc').on('activeChange', function() {

 		var id = $(this).attr("id");
 		var area;
 		if(id == "bottle-desc-10"){ area = $('area#map-part-horni-hory'); }
 		if(id == "bottle-desc-11"){ area = $('area#map-part-hasneky'); }
 		if(id == "bottle-desc-9"){ area = $('area#map-part-zadni-hora'); }
 		if(id == "bottle-desc-12"){ area = $('area#map-part-vinicni-statek'); }
 		
 		if($(this).hasClass("active")){
 			area.mapster('set', true);
 		}
 		else{
 			area.mapster('set', false);
 		}
 	});
 	
 	$("#vinice-map-wrap .vinice-overlay").hover(
 			function() { // in
 				var target = $(this).attr("targetarea");
 				$('#' + target).mapster('highlight', true);
 			},
 			function() { // out
 				$('#image-vinice').mapster('highlight', false);
 			}
 	);
 	
 	$("#vinice-map-wrap .vinice-overlay").click(function(){
 		var target = $(this).attr("targetarea");
 		$("#" + target).click();
 	});
});