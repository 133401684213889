resizeIntro();

$(window).load(function(){
	
	$(window).on('resize',function(){
		resizeIntro();
	});
	
	// callback na cycle-initialized musi byt definovan pred inicializaci cycle()
	$(document).on('cycle-initialized', '#intro', function(){
		//console.log("preinit");
		var element = $("#intro .slide1");
		$(element).css({
    		'background-position': "50% 100%"
    	  });
		
		showSlideText(element);
		
		resizeIntro();
		
		setTimeout(function(){
			$("#intro").cycle('next');
        }, 6000); 
		//moveSliderBackground(element);
	});
	

	$("#intro").cycle({
		slides: '> .slide',
		autoHeight: false,
		speed: 800,
		timeout: 14000,
		loader:true,
		progressive: sliderImages,
		log: false
	});
	
	$('#intro').on( 'cycle-before', function(e, optionHash, outgoingSlideEl, incomingSlideEl, forwardFlag) {
		hideSlideText();
		$(incomingSlideEl).css('background-position', 'top center');
		resizeIntro();
	});
	
	$('#intro').on( 'cycle-after', function(e, optionHash, outgoingSlideEl, incomingSlideEl, forwardFlag) {
		resizeIntro();
		moveSliderBackground(incomingSlideEl);
	});
	
 	$("#intro2").cycle({
		slides: '> .slidex',
		autoHeight: false,
		speed: 800,
		timeout: 14000,
		paused: true,
		loader:false,
		log: false
	});
}); 

function showSlideText(element){
	
	var textAtt = $(element).attr("titleText");
	if (typeof textAtt !== typeof undefined && textAtt !== false) {
		var textArray = $(element).attr("titleText").split("#");
		var text = textArray[0];
		var color = (1 in textArray) ? textArray[1] : "black";
		var top = (2 in textArray) ? textArray[2] : "20%";
		
		$("#slider-text").html(text).css("top", top).css("color", color).fadeIn("slow");
	}
}

function hideSlideText(){
	$("#slider-text").fadeOut();
}

function moveSliderBackground(element){
	//console.log("timeout start");
	setTimeout(function(){
    	//console.log("move start");
		// FF nepodporuje 'background-position-y' a jQuery zase neumi animace na background-position...musi se na to jit touhle obklikou
		$({temporary_x: 50, temporary_y: 0}).animate({temporary_x: 50, temporary_y: 100}, {
		    duration: 2000,
		    step: function() {
		        var position = Math.round(this.temporary_x) + "% " + Math.round(this.temporary_y) + "%";
		        $(element).css("background-position",  position);
		    },
		    complete: function(){
		    	showSlideText(element);
			}
		});
		
		
    	/*$(element).animate({
    		'background-position': "50% 100%"
    	  }, 2000, function(){
    		  showSlideText(element);
    	  });*/
    	
    	slideId = $(element).attr("slide");
    	$("#intro2").cycle('goto', slideId);
    	
		resizeIntro();
    }, 6000);
}

function resizeIntro() {
	
	var winH = $(window).height();
	var winW = $(window).width();
	winH = winW / 2.6966; // 2.45 je poměr stran pro rozměr obrázku 1920x783px
	var hSliderBottom = winW / 13.027; // pomer stran donliho okousani slideru                                    
	var hSliderTop = winW / 28.2; // pomer stran horniho okousani slideru
	
	if($("#intro2 .cycle-slide-active").length){
		var hSliderText = $("#intro2 .cycle-slide-active").height(); // prostor pro textovou cast slideru
	}
	else {
		var hSliderText = $(".slidex1").height(); // prostor pro textovou cast slideru
	}
	$(".bckg-head").height(hSliderTop);
	$("#intro").height(winH);
	$("#intro2").height(hSliderText);
	
	$("#intro .slide").height(winH);
	
	$("#intro .slider-bottom").height(hSliderBottom);
	
	$("#intro .vino-sklenice").height(winH * 0.85);
}